@import "./variables.scss";

@keyframes blink {
  50% {
    border-color: #ff0000;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $heading-font-stack;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 2.25rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}

.App {
  color: $font-color;
  font-family: $body-font-stack;
  font-size: 110%;

  a {
    color: $primary-color;
  }
  a:hover {
    color: $secondary-dark;
  }

  .telebar {
    padding: 1.5rem 0;
  }

  .telebar .nav-item {
    font-weight: 600;
    font-family: $heading-font-stack;
  }

  .telebar .nav-link {
    color: $primary-color;
  }

  .telebar .nav-link:hover {
    color: $secondary-dark;
  }

  .telebar .tele-number .nav-link {
    font-weight: 800;
    font-size: 130%;
  }

  .icon-before {
    padding-right: 0.5rem;
    color: $secondary-light-2;
  }

  .tele-header {
    background-color: $primary-color;
    color: #fff;
    text-align: center;
    padding: 7rem 0;
  }
  .tele-title {
    margin: 0;
  }
  .tele-header .tele-title {
    font-size: 320%;
  }
  .tele-header .tele-number {
    margin-top: 2rem;
  }
  .tele-header .tele-number a {
    color: #fff;
  }
  .tele-header-small {
    background-color: $secondary-light;
    color: #fff;
    text-align: center;
    font-size: 220%;
    padding: 4rem 0;
  }

  section {
    padding: 3rem 0;
  }

  .bg-primary {
    background-color: $primary-color !important;
    color: #fff;
  }
  .bg-primary a {
    color: #fff;
  }

  .bg-primary {
    background-color: $primary-color !important;
    color: #fff;
  }

  .bg-secondary-light {
    background-color: $secondary-light-2 !important;
  }

  .signup {
    padding: 3rem;
    margin: 1rem;
    border-radius: 1em;
    background-color: $secondary-light;
    color: #fff;
    box-shadow: 0em 0em 1em $secondary-light-2;
  }

  .signup label a {
    color: #fff;
    font-weight: 600;
  }

  .signup .btn {
    border-radius: 0.5em;
  }

  .faq-card {
    background-color: transparent;
    border-color: transparent;
  }

  .faq-question {
    padding: 0;
    border-radius: inherit;
    background-color: inherit;
    border-color: inherit;
  }

  .faq-question button {
    color: $primary-color;
    padding: 0;
  }

  .faq-answer {
    padding: 0;
  }

  .faq-answer p {
    margin-bottom: 1.5rem;
  }

  .photo {
    border-radius: 2em;
    height: 40%;
    padding: 2em;
  }

  #mapid,
  .leaflet-container {
    height: 400px;
    border-radius: 0.5rem;
  }

  .thanks-to .sponsor-image {
    padding: 1em;
    width: 100%;
  }

  .tele-footer {
    padding: 3rem 0;
    background-color: $secondary-light;
    color: #fff;
  }
  .tele-footer a {
    color: #fff;
  }
  .about-entry {
    margin: 1rem;
    text-align: center;
  }
  .about-entry-img {
    padding: 1rem;
  }
  .media-entry {
    padding: 1rem 0;
    margin: 1rem 0;
  }
}
